import React, { useEffect, useState, useMemo, useRef } from "react";
import GoogleMapReact from "google-map-react";
// import PICKUPS from "../../constants/PickupsJSON";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PickupStoreBox from "../PickupStoreBox";
import {
  getDistanceFromLatLonInKm,
  googleApiKey,
  daysENToLocale,
  getURLParams,
  getZipcodeInfo,
} from "../../utils/helpers";
import { Marker } from "../Markers";
import useWindowSize from "../../hooks/useWindowSize";
import PickupOverview from "../PickupOverview";
import { baseURL } from "../../constants/baseURL";
import { country } from "../../utils/i18n";

const defaultMapOptions = {
  fullscreenControl: false,
  clickableIcons: false,
};

function Center() {
  return (
    <>
      <div
        className="bounce"
        style={{ backgroundColor: "blue", cursor: "pointer" }}
      />
      <div className="pulse" />
    </>
  );
}

export default function Pickup({
  latLng,
  activePickup,
  setActivePickup,
  address,
  setShippingMethod,
}) {
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [headerHight, setHeaderHeight] = useState(null);
  const [displayMarkers, setDisplayMarkers] = useState(false);
  const [center, setCenter] = useState(() => ({
    lat: latLng.lat,
    lng: latLng.lng,
  }));
  const [isLAD, setIsLAD] = useState(false);
  const location = useLocation();
  const urlParamsJSON = getURLParams(location);

  const [pickups, setPikups] = useState([]);
  const { t } = useTranslation();

  const pickupContainerRef = useRef(null);
  const { width, height } = useWindowSize();
  const userZipCode = localStorage.getItem("zipCode");
  const [isZoneAvailable, setIsZoneAvailable] = useState(false);

  const deliveryAvailable = async () => {
    if (userZipCode) {
      const zipcodeDetails = await getZipcodeInfo(userZipCode);
      if (!zipcodeDetails) {
        setIsZoneAvailable(false);
      } else {
        const isDeliveryAvailable = zipcodeDetails?.home_delivery?.find(
          (daySlot) => daySlot?.length
        );
        if (!isDeliveryAvailable) {
          setIsZoneAvailable(false);
        }
      }
    }
  };
  deliveryAvailable();
  const pickupsWithDistanceFromUserAndSlots = useMemo(() => {
    const pickupsWithDistance = pickups.map((pickup) => {
      const distance = getDistanceFromLatLonInKm(
        parseFloat(latLng.lat),
        parseFloat(latLng.lng),
        pickup.latitude,
        pickup.longitude
      );
      const openingHours = pickup?.opening_hours;
      const slots = [];
      Object.keys(openingHours).forEach((day, index) => {
        if (openingHours[day]?.available_slots) {
          slots.push({
            day,
            ...openingHours[day],
          });
        }
      });
      slots.sort(
        (slotA, slotB) =>
          daysENToLocale[slotA.day].id - daysENToLocale[slotB.day].id
      );
      return {
        ...pickup,
        distance,
        slots,
      };
    });
    const sortedPickupsWithDistance = pickupsWithDistance.sort(
      (a, b) => a.distance - b.distance
    );
    if (sortedPickupsWithDistance.length > 0) {
      window.rudderanalytics.track("Store Locator Map Displayed", {
        country,
        zipcode: userZipCode,
        pickups: sortedPickupsWithDistance[0],
        latitude: parseFloat(urlParamsJSON.lat),
        longitude: parseFloat(urlParamsJSON.lng),
      });
    }
    const sortedPickups =
      sortedPickupsWithDistance[0]?.distance > 3 && isZoneAvailable
        ? [
            {
              name: t("delivery.card.name"),
              latitude: parseFloat(urlParamsJSON.lat),
              longitude: parseFloat(urlParamsJSON.lng),
              address1: address,
              distance: 0,
              zapietID: 983021984,
            },
            ...sortedPickupsWithDistance,
          ]
        : sortedPickupsWithDistance;
    setActivePickup(sortedPickups[0]?.zapietID);
    setIsLAD(sortedPickups[0]?.distance === 0);
    return sortedPickups;
  }, [pickups, latLng]);

  useEffect(() => {
    const hubId = JSON.parse(localStorage.getItem("hubId"));
    const getPickupsUrl = hubId
      ? `${baseURL}/pickups/${hubId}`
      : `${baseURL}/pickups`;

    fetch(getPickupsUrl)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setPikups(data?.data);
        }
      });
  }, [isZoneAvailable]);

  useEffect(() => {
    setHeaderHeight(document.getElementById("maps-header")?.offsetHeight);
  }, [width, height]);

  useEffect(() => {
    setCenter({
      lat: latLng.lat,
      lng: latLng.lng,
    });
  }, [latLng]);

  useEffect(() => {
    if (selectedPickup) {
      setCenter({
        lat: selectedPickup.latitude,
        lng: selectedPickup.longitude,
      });
      // if (width < 1025) {
      //   document.getElementById("maps-header").style.display = "none";
      // } else {
      //   document.getElementById("maps-header").style.display = "block";
      // }
    }
    //  else {
    //   document.getElementById("maps-header").style.display = "block";
    // }
    if (activePickup && !selectedPickup) {
      if (width < 1025) {
        const position = document.getElementById(
          `pu-box-${activePickup}`
        )?.offsetLeft;
        pickupContainerRef.current.scroll({
          // -5 for the left margin
          left: position - 5,
          behavior: "smooth",
        });
      } else {
        const position = document.getElementById(
          `pu-box-${activePickup}`
        )?.offsetTop;
        pickupContainerRef.current.scroll({
          // -20 for the margin top
          top: position - 20,
          behavior: "smooth",
        });
      }
    }
  }, [activePickup, selectedPickup, width]);

  const displayMap = () => {
    if (!selectedPickup) {
      return true;
    }
    if (width > 1025) return true;
    return false;
  };

  return (
    <div
      className="pu-root"
      style={{
        height: `calc(100% - ${headerHight}px)`,
      }}
    >
      {displayMap() && (
        <div
          className="pu-root-map"
          style={{ height: "100%", width: "100%" }}
        >
          {/* This should be on a seperate component */}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleApiKey,
            }}
            center={center}
            defaultZoom={13}
            options={defaultMapOptions}
            onTilesLoaded={() => setDisplayMarkers(true)}
          >
            <Center
              lat={latLng.lat}
              lng={latLng.lng}
            />
            {pickupsWithDistanceFromUserAndSlots.map((pickup, index) => (
              <Marker
                lat={pickup.latitude}
                lng={pickup.longitude}
                text={index + 1}
                id={pickup.zapietID}
                activePickup={activePickup}
                setActivePickup={setActivePickup}
                setSelectedPickup={setSelectedPickup}
                displayMarkers={displayMarkers}
              />
            ))}
          </GoogleMapReact>
        </div>
      )}
      {/* Pickup stores boxes */}
      <div
        className="pu-box-container"
        ref={pickupContainerRef}
        style={{
          background: selectedPickup && "white",
          bottom: selectedPickup && width < 1025 && "0",
        }}
      >
        {selectedPickup ? (
          <PickupOverview
            pickup={selectedPickup}
            setSelectedPickup={setSelectedPickup}
          />
        ) : (
          <div
            style={
              pickupsWithDistanceFromUserAndSlots[0]?.distance === 0
                ? { rowGap: "30px", paddingTop: "30px" }
                : null
            }
            className="pu-box-subcontainer"
          >
            {pickupsWithDistanceFromUserAndSlots.map((pickup, index) => (
              <PickupStoreBox
                pickup={pickup}
                index={index + 1}
                isLAD={isLAD}
                activePickup={activePickup}
                setActivePickup={setActivePickup}
                setSelectedPickup={setSelectedPickup}
                setShippingMethod={setShippingMethod}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
