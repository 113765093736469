import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { daysENToLocale, getZipcodeInfo } from "../../utils/helpers";
import { country } from "../../utils/i18n";
import { printPrice } from "../../constants/utils";

const useStyles = makeStyles({
  root: {
    "&.MuiButton-root": {
      border: "1px solid rgba(51, 151, 96, 1);",
      textTransform: "none",
      background: "rgba(51, 151, 96, 1)",
      fontWeight: "400",
      padding: "4px 10px",
      fontSize: "14px",
      marginTop: "12px",
      lineHeight: "18px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(51, 151, 96, 1);",
      },
    },
  },
});

function formatDistance(d) {
  if (Math.round(d * 1000) < 1000) {
    return `${Math.round(d * 1000)} m`;
  }
  return `${d.toFixed(1)} km`;
}

export default function PickupStoreBox({
  pickup,
  index,
  activePickup,
  setActivePickup,
  setSelectedPickup,
  setShippingMethod,
  isLAD,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [zipCodeInfo, setZipCodeInfo] = useState();
  const shippingCost = printPrice(`+${zipCodeInfo?.shippingCost}`);

  useEffect(() => {
    const userZipCode = localStorage.getItem("zipCode");
    const zipCodeShippingCost = async () => {
      const data = await getZipcodeInfo(userZipCode);
      setZipCodeInfo(data);
    };
    zipCodeShippingCost();
  }, []);

  return (
    <div
      className="pu-box"
      id={`pu-box-${pickup?.zapietID}`}
      onClick={() => {
        if (pickup?.distance === 0) {
          setShippingMethod("lad");
        }
        setActivePickup(pickup?.zapietID);
        setSelectedPickup(pickup);
      }}
      style={{
        boxShadow:
          activePickup === pickup?.zapietID && "1px 1px 7px rgb(0 0 0 / 25%)",
      }}
    >
      {isLAD && (
        <div className="pu-box__shipping_method">
          {pickup?.distance === 0 ? (
            <LocalShippingIcon fontSize="16px" />
          ) : (
            <ShoppingCartIcon fontSize="16px" />
          )}
          <p>
            {pickup?.distance === 0 ? (
              <Trans
                i18nKey="component.shipping_method_lad"
                values={{ shippingCost }}
              />
            ) : (
              t("component.shipping_method_pr")
            )}
          </p>
        </div>
      )}
      <div className="pu-box__info">
        <div className="pu-box__header">
          <p className="pu-box__distance">{formatDistance(pickup?.distance)}</p>
          <p className="pu-box__name">{pickup?.name}</p>

          <p className="pu-box__address">
            {pickup?.address1}, {pickup?.zipcode} {pickup?.city}{" "}
          </p>
        </div>
        <div className="pu-box__footer">
          {pickup?.distance === 0 ? (
            ""
          ) : (
            <p className="pu-box__footer-title">
              {t("component.pickup_storebox.footer_title")}
            </p>
          )}
          <p className="pu-box__time-slots">
            {pickup?.slots
              ?.filter((slot) => slot?.available_slots)
              ?.map((day) => {
                const croppedDay = daysENToLocale[day.day][country].substring(
                  0,
                  3
                );
                const opens = day?.opens.split(":")[0];
                const opensMinutes = day?.opens.split(":")[1];

                const closes = day?.closes.split(":")[0];
                const closesMinutes = day?.closes.split(":")[1];

                return `${croppedDay}. ${opens}h${
                  opensMinutes !== "00" ? opensMinutes : ""
                } - ${closes}h${closesMinutes !== "00" ? closesMinutes : ""}`;
              })
              .join(" - ")}
          </p>
        </div>
        <Button variant="contained" className={classes.root}>
          {pickup?.distance === 0
            ? t("component.delivery_storebox.select_button")
            : t("component.pickup_storebox.select_button")}
        </Button>
      </div>
      <div
        className="pu-box__id"
        style={{
          background: activePickup === pickup?.zapietID && "#fc7033",
        }}
      >
        {index}
      </div>
    </div>
  );
}
