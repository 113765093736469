import React, { useState, useEffect } from "react";
import LocalDelivery from "../LocalDelivery";
import MapsHeader from "../MapsHeader";
import Pickup from "../Pickup";

export default function MapsRoot({ setActiveStep, address, latLng }) {
  const [shippingMethod, setShippingMethod] = useState("pr");

  const [activePickup, setActivePickup] = useState(null);

  return (
    <div
      className="maps-root"
      // style={{
      //   height: "calc(var(--vh, 1vh) * 100)",
      // }}
    >
      {/* Header component */}
      <MapsHeader
        shippingMethod={shippingMethod}
        setShippingMethod={setShippingMethod}
        setActiveStep={setActiveStep}
        address={address}
        setActivePickup={setActivePickup}
      />

      {/* LAD root component */}
      {shippingMethod === "lad" && <LocalDelivery address={address} />}

      {/* PR root component */}

      {shippingMethod === "pr" && (
        <Pickup
          latLng={latLng}
          activePickup={activePickup}
          setShippingMethod={setShippingMethod}
          setActivePickup={setActivePickup}
          address={address}
        />
      )}
    </div>
  );
}
